import React, { useState } from "react";
import Header2 from "library/text/headers/Header2";
import PrimaryButton from "library/buttons/PrimaryButton";

import { makeAuthenticatedPostRequest } from "utils/backend-api";
import { addMessage } from "redux/snackbarSlice";
import { useDispatch } from "react-redux";
import { LoadingButton } from "@mui/lab";
import { getIngestionData } from "redux/ingestionSlice";
import BodyText from "library/text/body/BodyText";
import BoostDetails from "./BoostDetails";

const styles = {
  header: {
    marginBottom: "30px",
  },
  subHeader: {
    marginBottom: "20px",
  },
  updateDetailsGrid: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: "25px",
  },
  halfModalTextField: {
    width: "230px",
  },
  fullModalTextField: {
    width: "100%",
  },
  timePicker: {
    height: "40px",
    width: "230px",
  },
  adsetNameCard: {
    backgroundColor: "#F7F5F5",
    borderRadius: "4px",
    margin: "10px 0",
    padding: "10px",
  },
  buttonDiv: {
    marginTop: "30px",
    display: "flex",
    justifyContent: "space-between",
  },
  button: {
    width: "200px",
  },
};

const handleStop = async (
  channel,
  adsetId,
  setStopResponsePending,
  dispatch,
  closeModal,
) => {
  setStopResponsePending(true);
  const response = await makeAuthenticatedPostRequest("stop", {
    channel,
    adsetId,
  });
  setStopResponsePending(false);

  if (response.success) {
    dispatch(
      addMessage({ message: "Your ad set has been successfully stopped!" }),
    );
    dispatch(getIngestionData());
  } else {
    dispatch(
      addMessage({
        message: `Request failed: ${response.message.replaceAll("\n", ", ")}`,
      }),
    );
  }

  closeModal();
};

export default function StopModal({
  adsetName,
  channel,
  adsetId,
  recommendedOn,
  currentBudget,
  boostAmount,
  submittedOn,
  expiredOn,
  close,
}) {
  const [stopResponsePending, setStopResponsePending] = useState(false);

  // eslint-disable-next-line react/no-unstable-nested-components
  function StopButton({ children, ...props }) {
    return stopResponsePending ? (
      <LoadingButton loading {...props}>
        {children}
      </LoadingButton>
    ) : (
      <PrimaryButton {...props}>{children}</PrimaryButton>
    );
  }

  const dispatch = useDispatch();

  return (
    <>
      <Header2 sx={styles.header}>Stop boost?</Header2>
      <BodyText sx={styles.subHeader}>
        If you change your mind, you'll need to reconfigure this boost.
      </BodyText>
      <BoostDetails
        adsetName={adsetName}
        currentBudget={currentBudget}
        recommendedOn={recommendedOn}
        boostAmount={boostAmount}
        submittedOn={submittedOn}
        expiredOn={expiredOn}
      />

      <div style={styles.buttonDiv}>
        <PrimaryButton sx={styles.button} variant="text" onClick={close}>
          Cancel
        </PrimaryButton>
        <StopButton
          variant="outlined"
          sx={{
            color: "red",
            borderColor: "red",
            ...styles.button,
          }}
          onClick={() => {
            handleStop(
              channel,
              adsetId,
              setStopResponsePending,
              dispatch,
              close,
            );
          }}
        >
          Stop
        </StopButton>
      </div>
    </>
  );
}
