import React, { useState } from "react";

import { IconButton } from "@mui/material";
import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined";

import { useDispatch, useSelector } from "react-redux";
import { jesterPostRequest } from "utils/jester-api";
import { markAllNotificationsAsRead } from "redux/notificationsSlice";
import NotificationsMenu from "./NotificationsMenu";

const styles = {
  notificationsBadge: {
    backgroundColor: "#EB5757",
    borderRadius: 100,
    color: "white",
    fontSize: "10px",
    fontWeight: "bold",
    minWidth: "11px",
    minHeight: "11px",
    padding: "2px",
    position: "absolute",
    right: 6,
    top: 6,
  },
};

export default function NotificationsButton() {
  const notifications = useSelector((state) => state.notifications?.data) || [];
  const dispatch = useDispatch();

  const [showMenu, setShowMenu] = useState(false);
  const [hasSeen, setHasSeen] = useState(false);

  function handleClick() {
    if (showMenu) setHasSeen(true);
    setShowMenu(!showMenu);
  }

  const unreadNotifications = notifications
    .filter((notification) => !notification.read_time)
    .map((notification) => notification.id);
  const unreadNotificationsAmount = unreadNotifications.length;

  async function handleReadNotifications() {
    if (unreadNotifications.length > 0) {
      const response = await jesterPostRequest("notifications", {
        notifications: unreadNotifications,
      });

      if (response?.data?.success) {
        dispatch(markAllNotificationsAsRead());
      }
    }
  }

  return (
    <div style={{ position: "relative" }}>
      <IconButton
        onClick={handleClick}
        sx={{
          marginBottom: "6px",
          marginRight: "10px",
          borderRadius: "16px",
          position: "relative",
        }}
      >
        {unreadNotificationsAmount > 0 && (
          <div style={styles.notificationsBadge}>
            {!hasSeen ? unreadNotificationsAmount : " "}
          </div>
        )}
        <NotificationsNoneOutlinedIcon />
      </IconButton>
      {showMenu && (
        <NotificationsMenu
          notifications={notifications}
          handleReadNotifications={handleReadNotifications}
        />
      )}
    </div>
  );
}
