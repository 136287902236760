import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { jesterGetRequest } from "utils/jester-api";
import { STATUS } from "utils/enums";

export const getPostsData = createAsyncThunk("asyncRedux/posts", async () => {
  return jesterGetRequest("page_posts");
});

export const getPostsFields = createAsyncThunk(
  "asyncRedux/postFields",
  async () => {
    return jesterGetRequest("post_form_fields");
  },
);

export const getTaxonomyOptions = createAsyncThunk(
  "asyncRedux/taxonomy",
  async () => {
    return jesterGetRequest("taxonomy");
  },
);

export const getTaggingOptions = createAsyncThunk(
  "asyncRedux/taggingOptions",
  async () => {
    return jesterGetRequest("tagging_options");
  },
);

export const createSelectorForGranularityLevel =
  (granularityLevel) => (state) => {
    const relevantAttributes = {};
    const { data } = state.postsData.taxonomyOptions;
    if (!data) {
      return null;
    }

    Object.entries(data).forEach(([attributeName, attributeData]) => {
      const hasRelevantGranularity =
        granularityLevel === attributeData.granularity_level;
      if (hasRelevantGranularity) {
        relevantAttributes[attributeName] = attributeData;
      }
    });

    return relevantAttributes;
  };

export const createPostSelector = (postId) => (state) => {
  const findFn = (post) => post.post_id === postId;
  const foundPost = state.postsData.data.find(findFn);

  return foundPost;
};

const postsSlice = createSlice({
  name: "postsSlice",
  initialState: {
    data: null,
    status: null,
    authError: null,
    fields: {},
    taxonomyOptions: {},
    taggingOptions: {},
  },
  reducers: {
    postsLogOut: (state, _) => {
      state.data = null;
      state.status = null;
      state.authError = null;
      state.fields = null;
      state.taxonomyOptions = null;
    },
  },
  extraReducers: {
    [getPostsData.pending]: (state, _) => {
      state.status = STATUS.LOADING;
    },
    [getPostsData.fulfilled]: (state, { payload }) => {
      state.data = payload.data;
      state.pages = payload.pages;
      state.status = STATUS.SUCCESS;
      state.authError = payload.authError;
    },
    [getPostsData.rejected]: (state, _) => {
      state.status = STATUS.FAILED;
      state.authError = true;
    },

    [getPostsFields.pending]: (state, _) => {
      state.fields.status = STATUS.LOADING;
    },
    [getPostsFields.fulfilled]: (state, { payload }) => {
      state.fields.data = payload.data;
      state.fields.status = STATUS.SUCCESS;
      state.fields.authError = payload.authError;
    },
    [getPostsFields.rejected]: (state, _) => {
      state.fields.status = STATUS.FAILED;
      state.fields.authError = true;
    },

    [getTaxonomyOptions.pending]: (state, _) => {
      state.taxonomyOptions.status = STATUS.LOADING;
    },
    [getTaxonomyOptions.fulfilled]: (state, { payload }) => {
      state.taxonomyOptions.data = payload.data;
      state.taxonomyOptions.status = STATUS.SUCCESS;
      state.taxonomyOptions.authError = payload.authError;
    },
    [getTaxonomyOptions.rejected]: (state, _) => {
      state.taxonomyOptions.status = STATUS.FAILED;
      state.taxonomyOptions.authError = true;
    },
    [getTaggingOptions.pending]: (state, _) => {
      state.taggingOptions.status = STATUS.LOADING;
    },
    [getTaggingOptions.fulfilled]: (state, { payload }) => {
      state.taggingOptions.data = payload.data;
      state.taggingOptions.status = STATUS.SUCCESS;
      state.taggingOptions.authError = payload.authError;
    },
    [getTaggingOptions.rejected]: (state, _) => {
      state.taggingOptions.status = STATUS.FAILED;
      state.taggingOptions.authError = true;
    },
  },
});

export const { postsLogOut } = postsSlice.actions;

export default postsSlice.reducer;
