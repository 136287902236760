import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { jesterGetRequest } from "utils/jester-api";
import { STATUS } from "utils/enums";

export const getBoostReportData = createAsyncThunk(
  "asyncRedux/boostReport",
  async () => {
    return jesterGetRequest("boost_report");
  },
);

const boostReportSlice = createSlice({
  name: "boostReportSlice",
  initialState: {
    data: null,
    status: null,
    authError: null,
  },
  reducers: {
    boostReportLogOut: (state, _) => {
      state.data = null;
      state.status = null;
      state.authError = null;
    },
  },
  extraReducers: {
    [getBoostReportData.pending]: (state, _) => {
      state.status = STATUS.LOADING;
    },
    [getBoostReportData.fulfilled]: (state, { payload }) => {
      state.data = payload.data;
      state.status = STATUS.SUCCESS;
      state.authError = payload.authError;
    },
    [getBoostReportData.rejected]: (state, _) => {
      state.status = STATUS.FAILED;
      state.authError = true;
    },
  },
});

export const { boostReportLogOut } = boostReportSlice.actions;

export default boostReportSlice.reducer;
