import React, { useState } from "react";
import PrimaryButton from "library/buttons/PrimaryButton";
import OutPointModal from "library/surface/ModalCentered";
import { capitalize } from "utils/data/strings";
import { deleteCredentials } from "utils/jester-api";
import {
  DeleteCredentialsModal,
  DeleteCredentialsButton,
} from "../PlatformCardTemplateComponents";

const styles = {
  signInButton: {
    width: "300px",
    margin: "10px 5px 5px 30px",
  },
  modal: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
};

function CredentialsButton({ SSOButton, channel, ...rest }) {
  return SSOButton ? (
    <SSOButton {...rest} />
  ) : (
    <PrimaryButton sx={styles.signInButton} variant="outlined" {...rest}>
      Sign in with {channel}
    </PrimaryButton>
  );
}

const handleDeleteCredentials = async (
  body,
  tokenType,
  closeModals,
  refetchData,
) => {
  const success = await deleteCredentials(body, tokenType);
  if (success) {
    refetchData();
    closeModals();
    return true;
  }
  return false;
};

export default function CredentialsForm({
  storedMetadata,
  hasCredentials,
  tokenType,
  channel,
  description,
  parentId,
  closeModals,
  refetchData,
  SSOButton,
  authenticatePlatform,
}) {
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const body =
    tokenType === "page"
      ? {
          parent_id: parentId,
          description,
          channel,
        }
      : {
          channel,
          description,
        };
  return (
    <>
      <OutPointModal
        modalOpen={deleteModalOpen}
        setModalOpen={setDeleteModalOpen}
        sx={styles.modal}
      >
        <DeleteCredentialsModal
          setModalOpen={() => setDeleteModalOpen(false)}
          deleteCredentials={() =>
            handleDeleteCredentials(body, tokenType, closeModals, refetchData)
          }
        />
      </OutPointModal>

      <div>
        <CredentialsButton
          SSOButton={SSOButton}
          channel={capitalize(channel)}
          disabled={
            hasCredentials || (channel === "shopify" && !storedMetadata)
          }
          onClick={authenticatePlatform}
        />
        {hasCredentials && (
          <DeleteCredentialsButton onClick={() => setDeleteModalOpen(true)} />
        )}
      </div>
    </>
  );
}
