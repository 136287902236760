import React, { useState } from "react";
import { Button } from "@mui/material";
import OutPointModal from "library/surface/ModalCentered";
import AdvertiseModal from "./AdvertiseModal";

const styles = {
  button: {
    fontWeight: "bold",
  },
};

export default function AdvertiseButton({
  platform,
  credentialDescription,
  adCredentialId,
  postId,
  preFilledData,
}) {
  const [modalOpen, setModalOpen] = useState(false);

  return (
    <>
      <OutPointModal
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        sx={{ maxWidth: "1000px" }}
      >
        <AdvertiseModal
          preFilledData={preFilledData}
          platform={platform}
          onClose={() => setModalOpen(false)}
          credentialDescription={credentialDescription}
          postId={postId}
          adCredentialId={adCredentialId}
        />
      </OutPointModal>
      <Button
        color="green"
        variant="outlined"
        size="small"
        sx={styles.button}
        onClick={() => setModalOpen(true)}
      >
        Advertise
      </Button>
    </>
  );
}
