import React, { useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { useSelector, useDispatch, batch } from "react-redux";
import { ThemeProvider, StyledEngineProvider } from "@mui/material";

import "App.css";
import "normalize.css";

import theme from "themes/outpointTheme";
import PrivateRoute from "routing/privateRoute";

import LogInSignUpPage from "pages/login/LoginSignUpPage";

import { getJesterData, getMetadataFields } from "redux/jesterSlice";

import SnackbarManager from "library/surface/SnackbarManager";
import PAGES from "utils/navigation";

import OPAppBar from "library/navigation/AppBar";
import { getIngestionData } from "redux/ingestionSlice";
import { getBoostReportData } from "redux/boostReportSlice";
import { getMetricsData } from "redux/metricSlice";
import { getNotificationsData } from "redux/notificationsSlice";
import { getTimeOfDayData } from "redux/timeOfDaySlice";
import { getHourlyData } from "redux/hourlySlice";
import {
  getPostsData,
  getPostsFields,
  getTaxonomyOptions,
  getTaggingOptions,
} from "redux/postsSlice";
import { getFeatures } from "redux/featuresSlice";
import { getMonthlyAgileSocialData } from "redux/reportingSlice";
import { STATUS } from "utils/enums";

function App() {
  const dispatch = useDispatch();
  const authStatus = useSelector((state) => state.login.authStatus);
  const features = useSelector((state) => state.features.flags) || {};
  const featuresStatus = useSelector((state) => state.features.status);

  const pages = { ...PAGES };

  if (featuresStatus === STATUS.SUCCESS && !features.agile_social) {
    delete pages.show_posts;
    delete pages.reporting;
  }

  function mainAppWrapper(Component) {
    return (
      <>
        <OPAppBar pages={pages} />
        <div
          style={{
            padding: "30px 50px 30px 50px",
          }}
        >
          <Component />
        </div>
      </>
    );
  }

  useEffect(
    (_) => {
      if (authStatus) {
        batch(() => {
          dispatch(getFeatures());
          dispatch(getIngestionData());
          dispatch(getMonthlyAgileSocialData());
          dispatch(getJesterData());
          dispatch(getMetadataFields());
          dispatch(getMetricsData());
          dispatch(getBoostReportData());
          dispatch(getTimeOfDayData());
          dispatch(getHourlyData());
          dispatch(getPostsData());
          dispatch(getPostsFields());
          dispatch(getTaxonomyOptions());
          dispatch(getTaggingOptions());
          dispatch(getNotificationsData());
        });
      }
    },
    [authStatus],
  );

  return (
    <div className="App">
      <BrowserRouter>
        <StyledEngineProvider>
          <ThemeProvider theme={theme}>
            <Routes>
              {Object.keys(pages).map((feature) => {
                const { path: featurePath, component: featureComponent } =
                  PAGES[feature];

                return (
                  <Route
                    exact
                    path={featurePath}
                    key={feature}
                    element={
                      <PrivateRoute
                        component={mainAppWrapper(featureComponent)}
                      />
                    }
                  />
                );
              })}
              <Route exact path="/login" element={<LogInSignUpPage />} />
              <Route exact path="/signup" element={<LogInSignUpPage />} />
            </Routes>
            <SnackbarManager />
          </ThemeProvider>
        </StyledEngineProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;
