import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { jesterGetRequest } from "utils/jester-api";
import { STATUS } from "utils/enums";

export const getMonthlyAgileSocialData = createAsyncThunk(
  "asyncRedux/monthlyAgile",
  async () => {
    return jesterGetRequest("agile_monthly");
  },
);

const reportingSlice = createSlice({
  name: "ingestionSlice",
  initialState: {
    data: null,
    status: null,
    authError: null,
  },
  reducers: {
    reportingLogOut: (state, _) => {
      state.data = null;
      state.status = null;
      state.authError = null;
    },
  },
  extraReducers: {
    [getMonthlyAgileSocialData.pending]: (state, _) => {
      state.status = STATUS.LOADING;
    },
    [getMonthlyAgileSocialData.fulfilled]: (state, { payload }) => {
      state.data = payload.data;
      state.status = STATUS.SUCCESS;
      state.authError = payload.authError;
    },
    [getMonthlyAgileSocialData.rejected]: (state, _) => {
      state.status = STATUS.FAILED;
      state.authError = true;
    },
  },
});

export const { reportingLogOut } = reportingSlice.actions;

export default reportingSlice.reducer;
