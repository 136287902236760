/* eslint-disable no-nested-ternary */
/* eslint-disable camelcase */
import React, { useEffect, useState, useMemo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Grid, Button } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import Header1 from "library/text/headers/Header1";
import SubHead from "library/text/headers/SubHeadLight";
import OutPointDropdown from "library/buttons/OutPointDropdown";
import useAdsetPicker from "hooks/useAdsetPicker";

import { makeAuthenticatedPostRequest } from "utils/backend-api";
import { addMessage } from "redux/snackbarSlice";
import { getHourlyData } from "redux/hourlySlice";
import LogoChip from "pages/connections/components/LogoChip";
import { cardData } from "pages/connections/data/connectionsData";
import { BRAND_GRAY_700 } from "assets/palette";
import BodyText from "library/text/body/BodyText";
import LineChart, {
  getEasilyExtendableOptions,
} from "library/graphing/LineChart";
import { addCommasToDecimal } from "utils/data/strings";
import Header4 from "library/text/headers/Header4";
import { DAYS, HOURS, rawHourTo24hClock } from "utils/data/dates";
import OutPointToggle from "library/buttons/OutPointToggle";
import IntervalSelectionPanel from "./IntervalSelectionPanel";

const styles = {
  subhead: {
    marginBottom: "20px",
  },
  selector: {
    marginRight: "20px",
  },
  adsetDisplayBox: {
    border: `1px solid ${BRAND_GRAY_700}`,
    borderRadius: "8px",
    padding: "10px 0",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
};

const sortIntervals = (a, b) => {
  const startDayIndexA = DAYS.indexOf(a.startDay);
  const startDayIndexB = DAYS.indexOf(b.startDay);
  const startHourIndexA = DAYS.indexOf(a.startTime);
  const startHourIndexB = DAYS.indexOf(b.startTime);

  if (startDayIndexA < startDayIndexB) {
    return -1;
  }

  if (startDayIndexB < startDayIndexA) {
    return 11;
  }

  if (startHourIndexA < startHourIndexB) {
    return -1;
  }

  if (startHourIndexA > startHourIndexB) {
    return 1;
  }

  return 0;
};

function OptimizationPage() {
  let loadedIntervals = false;
  const fetchedIntervals = useSelector((state) => state.timeOfDay.data) || [];

  const ingestionData = useSelector((state) => state.ingestion.data) || [];

  const headerElement = useRef(null);

  const [intervals, setIntervals] = useState({});
  const [pending, setPending] = useState(false);
  const [rankData, setRankData] = useState([]);
  const [predictedIntervals, setPredictedIntervals] = useState([]);
  const [usePredictions, setUsePredictions] = useState({});

  const {
    brandSelection,
    channelSelection,
    adsetSelection,
    brands,
    channels,
    adsets,
    changeBrand,
    changeChannel,
    changeAdset,
  } = useAdsetPicker(ingestionData);

  const dispatch = useDispatch();

  const selectedAdsetId = useMemo(() => {
    if (
      brandSelection === "-" ||
      channelSelection === "-" ||
      adsetSelection === "-"
    )
      return "";
    const adset = ingestionData.filter(
      (row) =>
        row.brand?.includes(brandSelection) &&
        channelSelection === row.channel &&
        adsetSelection === row.adset_name,
    );
    if (adset.length <= 0) return "";
    return adset[0].id;
  }, [brandSelection, channelSelection, adsetSelection]);

  const usePredictionsForCurrentAdset = usePredictions[selectedAdsetId];
  const currentIntervals = usePredictionsForCurrentAdset
    ? predictedIntervals
    : intervals;

  const handleAddInterval = () => {
    setIntervals((oldIntervals) => {
      const newIntervals = { ...oldIntervals };
      if (!newIntervals[selectedAdsetId]) newIntervals[selectedAdsetId] = [];
      const newIntervalsForAdset = newIntervals[selectedAdsetId];
      newIntervalsForAdset.push({
        startDay: "",
        endDay: "",
        startHour: "",
        endHour: "",
      });
      newIntervals[selectedAdsetId] = newIntervalsForAdset;
      return newIntervals;
    });
  };

  const handleRemoveInterval = (index) => {
    setIntervals((oldIntervals) => {
      const newIntervals = { ...oldIntervals };
      if (!newIntervals[selectedAdsetId]) return newIntervals;
      const newIntervalsForAdset = newIntervals[selectedAdsetId];
      newIntervalsForAdset.splice(index, 1);
      newIntervals[selectedAdsetId] = newIntervalsForAdset;
      return newIntervals;
    });
  };

  const handleSetField = (fieldName, fieldValue, intervalIndex) => {
    setIntervals((oldIntervals) => {
      const newIntervals = { ...oldIntervals };
      if (!newIntervals[selectedAdsetId]) return newIntervals;
      const newIntervalsForAdset = newIntervals[selectedAdsetId];
      newIntervalsForAdset[intervalIndex][fieldName] = fieldValue;
      newIntervals[selectedAdsetId] = newIntervalsForAdset;
      return newIntervals;
    });
  };

  const canSubmit =
    selectedAdsetId &&
    currentIntervals[selectedAdsetId]?.length > 0 &&
    currentIntervals[selectedAdsetId]?.reduce(
      (acc, { startDay, endDay, startHour, endHour }) =>
        startDay && endDay && startHour && endHour && acc,
      true,
    );

  const handleSubmit = async () => {
    if (!canSubmit) {
      return;
    }

    const sortedIntervals = [...currentIntervals[selectedAdsetId]].sort(
      sortIntervals,
    );
    let intervalsOverlap = false;
    sortedIntervals.forEach((interval, i) => {
      if (i === sortedIntervals.length - 1) {
        return;
      }

      const nextInterval = sortedIntervals[i + 1];
      const intervalEndDayIndex = DAYS.indexOf(interval.endDay);
      const intervalEndTimeIndex = HOURS.indexOf(interval.endHour);
      const nextIntervalStartDayIndex = DAYS.indexOf(nextInterval.startDay);
      const nextIntervalStartTimeIndex = HOURS.indexOf(nextInterval.startHour);

      if (intervalEndDayIndex > nextIntervalStartDayIndex) {
        intervalsOverlap = true;
        return;
      }

      if (
        intervalEndDayIndex === nextIntervalStartDayIndex &&
        intervalEndTimeIndex > nextIntervalStartTimeIndex
      ) {
        intervalsOverlap = true;
      }
    });

    if (intervalsOverlap) {
      dispatch(
        addMessage({
          message:
            "Some intervals have overlaping times. Please fix this and resubmit.",
        }),
      );
      return;
    }

    setPending(true);

    const response = await makeAuthenticatedPostRequest("intervals", {
      intervals: currentIntervals[selectedAdsetId].map((row) => ({
        start_day: row.startDay,
        end_day: row.endDay,
        start_time: row.startHour,
        end_time: row.endHour,
      })),
      adset_id: selectedAdsetId,
    });

    setPending(false);

    if (response.success) {
      dispatch(addMessage({ message: "Updated time intervals!" }));
      dispatch(getHourlyData);
    } else {
      dispatch(
        addMessage(
          `There was an error updating time intervals: ${response.message}`,
        ),
      );
    }
  };

  useEffect(() => {
    if (!loadedIntervals) {
      const newIntervals = {};
      fetchedIntervals.forEach(
        ({ start_day, end_day, start_time, end_time, id }) => {
          if (!newIntervals[id]) {
            newIntervals[id] = [];
          }

          newIntervals[id].push({
            startDay: start_day,
            endDay: end_day,
            startHour: start_time,
            endHour: end_time,
          });
        },
      );
      setIntervals(newIntervals);
      loadedIntervals = true;
    }
  }, [fetchedIntervals.length]);

  useEffect(() => {
    const getRankData = async () => {
      const response = await makeAuthenticatedPostRequest("rank", {
        returnIntervals: true,
        enablePrediction: true,
        boostPerAdset: ingestionData.reduce(
          (obj, row) => ({
            ...obj,
            [row.id]: row.boost_amount,
          }),
          {},
        ),
      });
      setRankData(response?.intra_channel_ranking || []);
      const formattedPredictedIntervals = {};
      Object.keys(response?.predicted_intervals || {}).forEach((key) => {
        formattedPredictedIntervals[key] = response.predicted_intervals[
          key
        ].map(({ start_day, end_day, start_time, end_time }) => ({
          startDay: start_day,
          endDay: end_day,
          startHour: rawHourTo24hClock(start_time),
          endHour: rawHourTo24hClock(end_time),
        }));
      });
      setPredictedIntervals(formattedPredictedIntervals);
    };
    getRankData();
  }, [ingestionData]);

  const graphData = useMemo(() => {
    return Array.from({ length: 24 }, (_, index) => {
      return {
        x: `${index < 10 ? "0" : ""}${index}:00`,
        y: rankData[index]?.[selectedAdsetId]?.score,
      };
    });
  }, [selectedAdsetId]);

  // eslint-disable-next-line react/no-unstable-nested-components
  function ApplyButton({ children, ...props }) {
    return pending ? (
      <LoadingButton loading {...props}>
        {children}
      </LoadingButton>
    ) : (
      <Button {...props}>{children}</Button>
    );
  }

  const graphOptions = getEasilyExtendableOptions();
  graphOptions.yScaleOptions.formatTicks = (val) => addCommasToDecimal(val);
  graphOptions.tooltipPluginOptions.formatTitle = (val) => val;

  return (
    <Grid container columnSpacing={3}>
      <Grid item xs={12} ref={headerElement}>
        <Header1>Optimization</Header1>
        <SubHead sx={styles.subhead} color="secondary">
          Select times to activate or deactivate your adsets
        </SubHead>
        <div
          style={{
            marginBottom: "30px",
            paddingBottom: "20px",
            borderBottom: "1px solid #E4DBDB",
          }}
        >
          <OutPointDropdown
            sx={styles.selector}
            menuItems={brands || [""]}
            selectedValue={brandSelection || "-"}
            onChange={changeBrand}
            leading="Brand: "
          />
          <OutPointDropdown
            sx={styles.selector}
            menuItems={channels || [""]}
            selectedValue={channelSelection || "-"}
            onChange={changeChannel}
            leading="Channel: "
            disabled={!brandSelection}
          />
          <OutPointDropdown
            sx={styles.selector}
            menuItems={adsets || [""]}
            selectedValue={adsetSelection || "-"}
            onChange={changeAdset}
            leading="Adset: "
            disabled={!brandSelection || !channelSelection}
            isSentenceCaseMenu={false}
          />
        </div>
      </Grid>
      <Grid
        item
        xs={5}
        // 113px = appbar height = 56px, app top padding = 30px, app bottom padding = 30px
        sx={{
          minHeight: `calc(100vh - 116px - ${
            headerElement?.current?.clientHeight || "0"
          }px)`,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div style={{ flex: "1 1 auto", overflowY: "auto", height: "0px" }}>
          <div
            style={{
              display: "flex",
              height: "100%",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <div style={{ overflowY: "auto", height: "auto" }}>
              {adsetSelection === "-" ||
              channelSelection === "-" ||
              brandSelection === "-" ? (
                <BodyText>Select an adset to view intervals.</BodyText>
              ) : currentIntervals[selectedAdsetId]?.length > 0 ? (
                currentIntervals[selectedAdsetId]?.map(
                  ({ startDay, endDay, startHour, endHour }, index) => (
                    <IntervalSelectionPanel
                      key={startDay + endDay + startHour + endHour}
                      editable={!usePredictionsForCurrentAdset}
                      index={index}
                      startDay={startDay}
                      endDay={endDay}
                      startHour={startHour}
                      endHour={endHour}
                      setStartDay={(value) =>
                        handleSetField("startDay", value, index)
                      }
                      setEndDay={(value) =>
                        handleSetField("endDay", value, index)
                      }
                      setStartHour={(value) =>
                        handleSetField("startHour", value, index)
                      }
                      setEndHour={(value) =>
                        handleSetField("endHour", value, index)
                      }
                      removeInterval={() => handleRemoveInterval(index)}
                    />
                  ),
                )
              ) : (
                <BodyText>
                  {usePredictionsForCurrentAdset
                    ? "There are no suggested intervals for this adset."
                    : "There are no time intervals set for this adset. Create an interval using the button below."}
                </BodyText>
              )}
            </div>
            <div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  marginBottom: "10px",
                }}
              >
                <OutPointToggle
                  checked={!!usePredictionsForCurrentAdset}
                  onChange={() => {
                    setUsePredictions((oldPredictions) => {
                      const newPredictions = { ...oldPredictions };
                      newPredictions[selectedAdsetId] =
                        !newPredictions[selectedAdsetId];
                      return newPredictions;
                    });
                  }}
                  disabled={!selectedAdsetId}
                />
                <BodyText>Use predicted intervals</BodyText>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Button
                  variant="outlined"
                  onClick={handleAddInterval}
                  disabled={!!usePredictionsForCurrentAdset || !selectedAdsetId}
                >
                  + Add new interval
                </Button>
                <ApplyButton
                  disabled={!canSubmit}
                  variant="contained"
                  onClick={handleSubmit}
                >
                  Apply
                </ApplyButton>
              </div>
            </div>
          </div>
        </div>
      </Grid>
      <Grid xs={7} item>
        {adsetSelection !== "-" ? (
          <div>
            <div style={styles.adsetDisplayBox}>
              <LogoChip
                sx={{ marginRight: "7px" }}
                size="17px"
                logo={cardData[channelSelection]?.logo}
                channel={channelSelection}
              />
              {" - "}
              {adsetSelection}
            </div>
            <Header4 sx={{ marginTop: "20px" }}>
              Predicted performance by Hour of Day
            </Header4>
            <LineChart
              data={[
                {
                  linePoints: graphData,
                  color: "blue",
                  name: "score",
                },
              ]}
              moreOptions={graphOptions}
            />
          </div>
        ) : (
          <div />
        )}
      </Grid>
    </Grid>
  );
}

export default OptimizationPage;
