import axios from "axios";

export async function jesterPostRequest(route, payload) {
  const Token = localStorage.getItem("Token");
  if (payload.credentials) {
    payload.credentials = JSON.stringify(payload.credentials);
  }

  const result = await axios.post(
    `${process.env.REACT_APP_DOMAIN}/${route}`,
    payload,
    {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    },
  );
  return result;
}

export async function jesterGetRequest(route) {
  const Token = localStorage.getItem("Token");
  const result = await axios.get(`${process.env.REACT_APP_DOMAIN}/${route}`, {
    headers: {
      Authorization: `Bearer ${Token}`,
    },
  });
  return result.data;
}

export async function goToURL(
  channelName,
  description,
  credentials,
  whereFrom,
  tokenType,
) {
  try {
    const req = {
      channel: channelName,
      description,
      from: whereFrom,
      token_type: tokenType,
    };
    if (tokenType === "page") {
      req.parent_id = credentials.parent_credential_id;
    }

    const result = await jesterPostRequest("connect", req);
    window.location.href = result.data.url;
  } catch (err) {
    // eslint-disable-next-line no-console
    console.warn(err);
  }
}

export const deleteCredentials = async (body, tokenType) => {
  try {
    const response = await jesterPostRequest("credentials", {
      ...body,
      token_type: tokenType,
      delete: true,
    });
    if (response.data?.success) {
      return true;
    }
  } catch (err) {
    // eslint-disable-next-line no-console
    console.warn(err);
  }

  return false;
};
