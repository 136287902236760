import React, { useState } from "react";
import { getJesterData } from "redux/jesterSlice";
import { getIngestionData } from "redux/ingestionSlice";
import Spinner from "library/progress/Spinner";
import { jesterPostRequest } from "utils/jester-api";
import { useDispatch } from "react-redux";
import OPAutoComplete from "library/form/OutPointAutoComplete";

const styles = {
  selector: {
    minWidth: 150,
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
};

function AdsetBrandPicker({ rowData }) {
  const { adset_brand: adsetBrand, brand: brands, adset_id: adsetId } = rowData;

  const dispatch = useDispatch();
  const currentValDisplay = adsetBrand || "Unselected";

  const [isRequestPending, setIsRequestPending] = useState(false);

  const refetchData = async () => {
    dispatch(getJesterData());
    dispatch(getIngestionData());
  };

  const makeRequestBody = (newAdsetBrand) => {
    // eslint-disable-next-line no-console
    console.assert(
      brands.includes(newAdsetBrand),
      "Adset brand chosen must be from list of associated brands.",
    );
    return {
      adset_brand: newAdsetBrand,
      adset_id: adsetId,
    };
  };

  const updateAdset = async (newAdsetBrand) => {
    // TODO: prevent update if adsetBrand and newVal is the same:
    try {
      setIsRequestPending(true);
      const requestBody = makeRequestBody(newAdsetBrand);
      const response = await jesterPostRequest("brand_adset", requestBody);
      const isResponseSuccessful = response.data?.success;
      if (isResponseSuccessful) {
        await refetchData();
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    } finally {
      setIsRequestPending(false);
    }
  };

  const handleSelection = (_, newAdsetBrand) => {
    // eslint-disable-next-line no-console
    console.assert(
      brands.includes(newAdsetBrand),
      "Chosen value must be from the list of brands associated only.",
    );
    const isWorthUpdating = newAdsetBrand !== adsetBrand;
    if (!isWorthUpdating) {
      // eslint-disable-next-line no-console
      console.warn(
        "Skipping backend call to update adset since there is no change in the adset_brand value",
      );

      return;
    }
    updateAdset(newAdsetBrand);
  };

  return isRequestPending ? (
    <Spinner />
  ) : (
    <OPAutoComplete
      sx={styles.selector}
      name="Adset Brand"
      key={currentValDisplay}
      options={brands}
      value={adsetBrand}
      autoComplete
      openOnFocus
      selectOnFocus
      handleHomeEndKeys
      forcePopupIcon
      onChange={handleSelection}
    />
  );
}

export default AdsetBrandPicker;
