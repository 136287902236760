import React, { useState } from "react";

import { useSelector, useDispatch } from "react-redux";
import { Grid } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import Label from "library/text/body/LabelText";
import Header2 from "library/text/headers/Header2";
import SubHead from "library/text/headers/SubHead";
import OutPointTextField from "library/form/OutPointTextField";
import OPDatePicker from "library/form/DatePicker";
import OPTimePicker from "library/form/TimePicker";
import Header4 from "library/text/headers/Header4";
import OutPointSelector from "library/form/OutPointSelector";
import PrimaryButton from "library/buttons/PrimaryButton";
import { keyBy } from "lodash";

import { RED_GREY } from "assets/palette";
import { TWO_DECIMAL_REGEX, WHOLE_NUMBER_REGEX } from "utils/regex";
import { makeAuthenticatedPostRequest } from "utils/backend-api";
import { addMessage } from "redux/snackbarSlice";
import { createPostSelector } from "redux/postsSlice";
import TaxonomyForm from "./TaxonomyForm";

const styles = {
  selector: {
    width: "100%",
  },
};

const NAME_FIELDS = [
  {
    name: "Campaign",
    id: "campaign_name",
    granularityLevel: "campaign",
  },
  {
    name: "Ad set",
    id: "adset_level_name",
    granularityLevel: "adset_level",
  },
  {
    name: "Creative",
    id: "creative_level_name",
    granularityLevel: "creative_level",
  },
];

const STANDARD_FIELDS = [
  { id: "post_table_id" },
  { id: "budget" },
  { id: "end_date" },
  { id: "end_time" },
  ...NAME_FIELDS,
];

const createPagePostAd = async (userInputs, dispatch, setPending, onClose) => {
  setPending(true);
  const pagePostResponse = await makeAuthenticatedPostRequest(
    "page_post_ad",
    userInputs,
  );
  setPending(false);
  if (!pagePostResponse.success) {
    dispatch(addMessage({ message: pagePostResponse.message }));
  } else {
    dispatch(
      addMessage({
        message: "Ad created successfully. Check the platform site to confirm.",
      }),
    );
    onClose();
  }
};

function AdvertiseButton({ pending, children, ...props }) {
  return pending ? (
    <LoadingButton
      sx={{
        width: "100%",
      }}
      loading
      {...props}
    >
      {children}
    </LoadingButton>
  ) : (
    <PrimaryButton {...props}>{children}</PrimaryButton>
  );
}

const submitAdvertisement = async (
  userInputs,
  setUserInputErrors,
  requiredFields,
  onClose,
  dispatch,
  setPending,
  post,
  savedAudienceOptions,
) => {
  let didError = false;
  [...STANDARD_FIELDS, ...requiredFields].forEach(({ id }) => {
    if (!userInputs[id]) {
      didError = true;
      setUserInputErrors((prevErrors) => {
        const newErrors = { ...prevErrors };
        newErrors[id] = true;
        return newErrors;
      });
    }
  });

  if (didError) {
    return;
  }

  /// change value of saved audiences from name to id:
  const formattedUserInputs = { ...userInputs };
  const savedAudName = userInputs.saved_audience;
  const resolvedAud = savedAudienceOptions.nameToIdMap?.[savedAudName];
  formattedUserInputs.saved_audience = resolvedAud;

  try {
    createPagePostAd(formattedUserInputs, dispatch, setPending, onClose);
  } catch (error) {
    dispatch(addMessage({ message: error }));
  }
};

const resolveFieldInput = (
  inputType,
  id,
  name,
  userInputs,
  setUserInputs,
  userInputErrors,
  setUserInputErrors,
  rest,
) => {
  switch (inputType) {
    case "text":
      return (
        <OutPointTextField
          name={name}
          value={userInputs[id] || ""}
          error={userInputErrors[id]}
          onChange={(newInput) => {
            setUserInputErrors((prevState) => {
              const newState = { ...prevState };
              newState[id] = false;
              return newState;
            });
            setUserInputs((prevState) => {
              const newState = { ...prevState };
              newState[id] = newInput;
              return newState;
            });
          }}
        />
      );
    case "monetary":
      return (
        <OutPointTextField
          name={name}
          value={userInputs[id] || ""}
          startAdornment="$"
          error={userInputErrors[id]}
          onChange={(newInput) => {
            if (!TWO_DECIMAL_REGEX.test(newInput)) {
              return;
            }
            setUserInputErrors((prevState) => {
              const newState = { ...prevState };
              newState[id] = false;
              return newState;
            });
            setUserInputs((prevState) => {
              const newState = { ...prevState };
              newState[id] = newInput;
              return newState;
            });
          }}
        />
      );
    case "singleselect":
      return (
        <>
          <Label sx={{ marginBottom: "3px" }}>{name}</Label>
          <OutPointSelector
            selectedValue={userInputs[id] || []}
            menuItems={rest.options}
            error={userInputErrors[id]}
            isSentenceCaseMenu={false}
            onChange={(newInput) => {
              setUserInputErrors((prevState) => {
                const newState = { ...prevState };
                newState[id] = false;
                return newState;
              });
              setUserInputs((prevState) => {
                const newState = { ...prevState };
                newState[id] = newInput;
                return newState;
              });
            }}
            sx={styles.selector}
          />
        </>
      );
    case "multiselect":
      return (
        <>
          <Label sx={{ marginBottom: "3px" }}>{name}</Label>
          <OutPointSelector
            selectedValue={userInputs[id] || []}
            menuItems={rest.options}
            error={userInputErrors[id]}
            isSentenceCaseMenu={false}
            onChange={(newInput) => {
              setUserInputErrors((prevState) => {
                const newState = { ...prevState };
                newState[id] = false;
                return newState;
              });
              setUserInputs((prevState) => {
                const newState = { ...prevState };
                newState[id] = newInput;
                return newState;
              });
            }}
            sx={styles.selector}
            multiple
          />
        </>
      );
    case "number":
      return (
        <OutPointTextField
          name={name}
          value={userInputs[id] || ""}
          error={userInputErrors[id]}
          onChange={(newInput) => {
            if (!WHOLE_NUMBER_REGEX.test(newInput) && newInput !== "") {
              return;
            }
            setUserInputErrors((prevState) => {
              const newState = { ...prevState };
              newState[id] = false;
              return newState;
            });
            setUserInputs((prevState) => {
              const newState = { ...prevState };
              newState[id] = newInput;
              return newState;
            });
          }}
        />
      );
    default:
      return <div />;
  }
};

const createAudienceFieldSelector = (platform, adCredentialId) => (state) => {
  const platformDataOptions =
    state.jester?.data?.integration_level?.ad?.[platform];
  const relevantAdPlatformData = platformDataOptions
    ? platformDataOptions.find((option) => option.id === adCredentialId)
    : {};
  const savedAudiences = relevantAdPlatformData?.saved_audiences || [];
  const idToNameMap = keyBy(savedAudiences, (aud) => aud.audience_id);
  const nameToIdMap = keyBy(savedAudiences, (aud) => aud.audience_name);

  return {
    idToNameMap,
    nameToIdMap,
  };
};

const createUniqueFieldsSelector = (platform, adCredentialId) => (state) => {
  let uniqueFields = state.postsData.fields?.data?.[platform] || [];
  const platformDataOptions =
    state.jester?.data?.integration_level?.ad?.[platform];
  const relevantAdPlatformData = platformDataOptions
    ? platformDataOptions.find((option) => option.id === adCredentialId)
    : {};
  const savedAudiences = relevantAdPlatformData?.saved_audiences;
  if (savedAudiences) {
    const savedAudiencesField = {
      id: "saved_audience",
      name: "Saved Audience",
      options: savedAudiences.map((s) => s.audience_name),
      type: "singleselect",
      optional: platform === "tiktok",
    };

    uniqueFields = [savedAudiencesField, ...uniqueFields];
  }

  return uniqueFields;
};

function AdvertiseModal({
  platform,
  onClose,
  credentialDescription,
  postId,
  preFilledData,
  adCredentialId,
}) {
  const dispatch = useDispatch();
  const [pending, setPending] = useState(false);

  const post = useSelector(createPostSelector(postId));
  const uniqueFields = useSelector(
    createUniqueFieldsSelector(platform, adCredentialId),
  );
  const savedAudienceOptions = useSelector(
    createAudienceFieldSelector(platform, adCredentialId),
  );

  const [userInputs, setUserInputs] = useState({
    credential_description: credentialDescription,
    post_id: postId,
    platform_name: platform,
    ...[...STANDARD_FIELDS, ...uniqueFields].reduce((acc, row) => {
      const field = row.id;

      const currValue = preFilledData[field];
      if (currValue !== null && currValue !== undefined) {
        acc[field] = currValue;
      }
      return acc;
    }, {}),
  });

  const [userInputErrors, setUserInputErrors] = useState({});

  const nameForm = (
    <Grid
      direction="column"
      container
      sx={{
        gap: "20px",
        padding: "20px",
        borderRadius: "25px",
        background: RED_GREY,
        marginBottom: "40px",
      }}
    >
      <Grid item>
        <SubHead>Advertisement Details</SubHead>
      </Grid>
      {NAME_FIELDS.map(({ id, name, granularityLevel }) => {
        const onNameUpdate = (newName) => {
          setUserInputErrors((prevState) => {
            const newState = { ...prevState };
            newState[id] = false;
            return newState;
          });
          setUserInputs((prevState) => {
            const newState = { ...prevState };
            newState[id] = newName;
            return newState;
          });
        };

        const inputField = post.hasTaxonomyRule ? (
          <TaxonomyForm
            granularityLevel={granularityLevel}
            post={post}
            onNameUpdate={(updatedName) => onNameUpdate(updatedName)}
          />
        ) : (
          <OutPointTextField
            name={`${name} name`}
            value={userInputs?.id}
            error={userInputErrors[id]}
            onChange={(newInput) => {
              setUserInputErrors((prevState) => {
                const newState = { ...prevState };
                newState[id] = false;
                return newState;
              });
              setUserInputs((prevState) => {
                const newState = { ...prevState };
                newState[id] = newInput;
                return newState;
              });
            }}
          />
        );

        return (
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="baseline"
            item
            key={id}
          >
            <Grid item xs={12} sx={{ padding: "8px" }}>
              {inputField}
            </Grid>
          </Grid>
        );
      })}
    </Grid>
  );

  const adSetupForm = (
    <div style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
      <Grid container columnSpacing={2}>
        <Grid item xs={6}>
          {resolveFieldInput(
            "monetary",
            "budget",
            "Recommended budget",
            userInputs,
            setUserInputs,
            userInputErrors,
            setUserInputErrors,
            {},
          )}
        </Grid>
      </Grid>
      <Grid container columnSpacing={2}>
        <Grid item xs={12}>
          <Label sx={{ marginBottom: "3px" }}>Deadline date</Label>
        </Grid>
        <Grid item xs={6}>
          <OPDatePicker
            extraDateProps={{ borderRadius: "7px", width: "100%" }}
            startDate={userInputs.end_date || null}
            setStartDate={(newDate) =>
              setUserInputs((prevState) => {
                const newState = { ...prevState };
                newState.end_date = newDate;
                return newState;
              })
            }
          />
        </Grid>
        <Grid item xs={6}>
          <OPTimePicker
            extraTimeProps={{ borderRadius: "7px", width: "100%" }}
            time={userInputs.end_time || null}
            setTime={(newTime) =>
              setUserInputs((prevState) => {
                const newState = { ...prevState };
                newState.end_time = newTime;
                return newState;
              })
            }
          />
        </Grid>
      </Grid>

      <Grid container rowSpacing={2} columnSpacing={2}>
        {uniqueFields.map(({ id, name, type, ...rest }) => (
          <Grid xs={6} item>
            {resolveFieldInput(
              type,
              id,
              name,
              userInputs,
              setUserInputs,
              userInputErrors,
              setUserInputErrors,
              rest,
            )}
          </Grid>
        ))}
      </Grid>
    </div>
  );

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <Header2 sx={{ marginBottom: "30px" }}>Make this post an ad</Header2>
      {nameForm}
      <Header4 sx={{ marginBottom: "10px" }}>Set up your ad</Header4>
      {adSetupForm}
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          gap: "40px",
          marginTop: "40px",
        }}
      >
        <PrimaryButton variant="text" onClick={onClose}>
          Cancel
        </PrimaryButton>
        <AdvertiseButton
          pending={pending}
          onClick={() => {
            const requiredFields = uniqueFields.filter(
              ({ optional }) => !optional,
            );
            submitAdvertisement(
              userInputs,
              setUserInputErrors,
              requiredFields,
              onClose,
              dispatch,
              setPending,
              post,
              savedAudienceOptions,
            );
          }}
        >
          Advertise
        </AdvertiseButton>
      </div>
    </div>
  );
}

export default AdvertiseModal;
